import axios, { AxiosPromise, AxiosResponse } from 'axios';
import config from 'config';

import { getBackendLocale } from './Helper';
import { getLocalStorageAccessToken } from './ClientStateUtil';

import { DEFAULT_LOCALE } from '../constants/locale';
import { getLocalLanguage } from './PagePropertyUtil';

import { TvlkResponse } from '@traveloka/commons';
import { DefaultResponse } from '@aries/services';

/**
 * Aries Service Error
 * ---
 * Custom Error object with additionalData field.
 */
export class AriesServiceError<TAdditionalData> extends Error {
  public additionalData: TAdditionalData;

  constructor(additionalData: TAdditionalData, ...errorArgs: any[]) {
    super(...errorArgs);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AriesServiceError);
    }

    this.name = 'AriesServiceError';
    this.additionalData = additionalData;
  }
}

const handlePostRequest = (
  payload = {},
  locale = DEFAULT_LOCALE,
  isRequireToken = true
) => {
  const context = {
    authServiceToken: isRequireToken ? getLocalStorageAccessToken() : null,
    tvLifetime: '',
    tvSession: '',
    timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const clientInterface = 'MOBILE';

  return {
    data: payload,
    fields: [],
    context: {
      ...context,
      locale: getBackendLocale(locale || DEFAULT_LOCALE),
    },
    clientInterface,
  };
};

const getUrl = (apiUrl: string): string => {
  return config.apiHost + apiUrl;
};

const createErrorMessage = (httpCode: string, message: string) => {
  return `${httpCode ? httpCode + ': ' : ''}${message}`;
};

export interface PostResult<T> {
  tvlkResponse?: TvlkResponse<T>;
  response?: AxiosResponse;
  result?: T | null;
  error?: Error;
  errorCode?: string | null;
}

const wrapPromises = async <T extends DefaultResponse>(
  promise: AxiosPromise<TvlkResponse<T>>
): Promise<PostResult<T>> => {
  try {
    const response = await promise;
    const { data: tvlkResponse } = response;

    const data = tvlkResponse.data;

    if (data.status === 'FAILED') {
      throw new AriesServiceError(
        { response, tvlkResponse, result: data },
        createErrorMessage(data.errorCode, data.message || 'Error')
      );
    }

    return { response, tvlkResponse, result: data };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`
      Error while trying to connect to 
      ${error}
    `);

    let response = null;
    let result = null;
    let tvlkResponse = null;

    if (error instanceof AriesServiceError) {
      response = error.additionalData?.response || null;
      result = error.additionalData?.result || null;
      tvlkResponse = error.additionalData?.tvlkResponse || null;
    }

    return { error: error as Error, response, result, tvlkResponse };
  }
};

/**
 * example of usage:
 * post(...args)
 * .then((result) => {
 *   const { response, error } = result;
 * })
 */
export const post = <T extends DefaultResponse>(
  apiUrl: string,
  payload: { [key: string]: any; } = {},
  options: { [key: string]: any; } = {}
): Promise<PostResult<T>> => {
  const {
    isRequireToken = true,
    wrapPayload = true,
    locale: optionLocale,
  } = options;

  const { locale: payloadLocale } = payload;

  const cookieLocale = getLocalLanguage();

  // the reason we still use option and payload locale is to support backward compability
  // payloadLocale and optionLocale should be deprecated in near future
  const locale =
    payloadLocale || optionLocale || cookieLocale || DEFAULT_LOCALE;

  return wrapPromises(
    axios.post<TvlkResponse<T>>(
      getUrl(apiUrl),
      wrapPayload ? handlePostRequest(payload, locale, isRequireToken) : payload
    )
  );
};

export const uploadFile = <T extends DefaultResponse>(
  apiUrl: string,
  file: any
) => {
  const data = new FormData();
  data.append('data', file);
  data.append('main-request', JSON.stringify(handlePostRequest()));

  // TODO: Temporay fix, remove this after backend updated
  delete axios.defaults.headers.common.Accept;

  return wrapPromises(
    axios.post<TvlkResponse<T>>(getUrl(apiUrl), data, {
      headers: { 'content-type': 'multipart/form-data' },
    })
  );
};

export default {
  handlePostRequest,
};
