import config from 'config';
import i18next, { i18n } from 'i18next';

export class Translator {
  private availableLocales: string[] = [];
  private loadedNamespace: Set<string> = new Set();

  constructor(availableLocales: string[]) {
    this.availableLocales = availableLocales;
  }

  setAvailabelLocales(availableLocales: string[]) {
    this.availableLocales = availableLocales;
  }

  getAvailableLocales() {
    return this.availableLocales;
  }

  addLoadedNamespace(namespace: string) {
    this.loadedNamespace.add(namespace);
  }

  getLoadedNameSpace() {
    return this.loadedNamespace;
  }
}

let instance: Translator;

const init = async (locale: string = 'en', _availableLocales?: string[]) => {
  await i18next.init({
    fallbackLng: locale,
    lng: locale,
    supportedLngs: _availableLocales,
    keySeparator: '.',
  });

  instance = new Translator(_availableLocales || [locale]);
};

type Locale = 'EN' | 'ID';

export interface TranslationUtilType {
  init: (locale?: string, availableLocales?: string[]) => Promise<void>;
  translate: (key: string, params?: { [key: string]: string; }) => string;
  setLocale: (locale: string) => Promise<void>;
  getLocale: () => Locale;
  load: (namspaces: string[]) => Promise<void>;
  getInstance: () => i18n;
}

const TranslationUtil: TranslationUtilType = {
  init,
  translate: (key: string, params?: { [key: string]: string; }) =>
    i18next.t(key, params),
  setLocale: async (locale: string) => {
    await i18next.changeLanguage(locale.toLowerCase());
  },
  getLocale: () => i18next.language.toUpperCase() as Locale,
  load: async namespaces => {
    for (const namespace of namespaces) {
      if (!instance.getLoadedNameSpace().has(namespace)) {
        for (const _locale of instance.getAvailableLocales()) {
          let translationsObject: any = null;

          try {
            translationsObject = await import(
              `translations/${namespace ? namespace.replace('.', '/') + '/' : ''
              }${_locale}.json`
            ).then(module => module.default);
          } catch (e) {
            console.log('i18n file not found');
          }

          if (translationsObject) {
            await i18next.addResourceBundle(
              _locale,
              namespace,
              translationsObject,
              true
            );
          }
        }
        instance.getLoadedNameSpace().add(namespace);
      }
    }
  },
  getInstance: () => {
    return i18next;
  },
};

TranslationUtil.init(
  config.translations.defaultLocale,
  config.translations.availableLocales
);

Object.freeze(TranslationUtil);

export default TranslationUtil;
